import React from 'react';
import SecondaryNav from '../../components/SecondaryNav';
import { Sticky } from '../../elements';
 
const PrintMenu = () => {
  const navItems = [
    {
      to: '/print',
      label: 'Coverage',
    },
    {
      to: '/print/rates-sizes',
      label: 'Rates & Sizes',
    },
    {
      to: '/print/specs',
      label: 'Specs',
    },
    {
      to: '/print/distribution',
      label: 'Distribution',
    },
  ];

  return (
    <Sticky zIndex={1}>
      <SecondaryNav
        items={navItems}
        color="dark"
        backgroundColor="white"
        hoverColor="bluegrey"
        activeColor="bluegrey"
        shadow={false}
        size={0.95}
      />
    </Sticky>
  );
};

export default PrintMenu;
