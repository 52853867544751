import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import AppContainer from '../../components/AppContainer';
import { Trail, config, animated } from 'react-spring';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import { Head, Sub } from '../../elements';
import PrintContainer from './PrintContainer';
import PreviewButton from './PreviewButton';
import { Config } from '../../queries';

const Outer = styled.div`
  @media screen and (max-width: 1217px) {
    margin-left: -4rem;
    margin-right: -4rem;
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    margin-left: -3rem;
    margin-right: -3rem;
    width: calc(100vw - -1rem);
  }
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
`;
export const PrintLayout = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 242px);
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  margin-bottom: 4rem;
  @media screen and (max-width: 1217px) {
    &::before,
    &::after {
      content: '';
    }
    width: calc(100vw - 4rem);
    grid-template-columns:
      3rem
      repeat(${props => props.items}, 260px)
      3rem;
  }
  @media screen and (max-width: 768px) {
    width: calc(100vw - 4rem);
    grid-template-columns:
      2rem
      repeat(${props => props.items}, 260px)
      1rem;
  }
`;

const PrimeContainer = styled.footer`
  margin-top: auto;
`;
export const ItemContainer = styled(animated.article)`
  background-color: ${props => props.theme.light};
  color: white;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  > figure > img {
    vertical-align: bottom;
    transition: transform 750ms ease;
  }
  &:hover {
    transition: background 300ms ease;
    background-color: ${props => props.theme.bluegrey};
    > figure > img {
      transition: transform 950ms ease;
      transform: scale(1.05);
    }
  }
`;
const ImgContainer = styled.figure`
  overflow: hidden;
  margin: 0;
  position: relative;
`;
const Title = styled.h2`
  padding: 1rem;
  margin: 0;
  font-size: 1.38rem;
  background-color: ${props => props.theme.dark};
  text-align: center;
`;
const Text = styled.p`
  padding: 1rem;
  color: ${props => props.theme.dark};
`;
const Red = styled.div`
  background-color: ${props => props.theme.red};
  padding: 0.5rem 1rem;
  font-weight: bold;
`;
const Position = styled.div`
  background-color: ${props => props.theme.primary};
  padding: 0.5rem 1rem;
`;
const CoverImg = styled.div`
  position: relative;
  > div > img {
    /* I don't understand why I have to do this :/ */
    position: relative !important;
    width: 100%;
    object-fit: cover;
    max-height: 170px;
    object-position: top;
  }
`;

const Prime = ({ children, title }) => (
  <PrimeContainer>
    <Red>Prime position{title === 'News' && 's'}</Red>
    <Position>{children}</Position>
  </PrimeContainer>
);

class PrintItem extends Component {
  state = {
    publication: null,
  };

  componentDidMount() {
    this.props.item.component &&
      fetch(this.props.graphqlEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: `query {
          pdfByPublication(publication: "${this.props.item.component}") {
            id
            thumbUrl
            publication
            fileUrl
            shortLink
            datePublished
          } 
        }`,
        }),
      })
        .then(res => res.json())
        .then(res => res.data && this.setState({ publication: res.data.pdfByPublication }))
        .catch(error => console.log(error));
  }

  render() {
    const { publication } = this.state;
    const {
      style,
      item: { label, text, image, extraInfo },
    } = this.props;
    return (
      <ItemContainer style={style}>
        <header>
          <Title>{label}</Title>
          {image && (
            <ImgContainer>
              <CoverImg>
                <PreviewCompatibleImage imageInfo={image} />
              </CoverImg>
            </ImgContainer>
          )}
        </header>
        <Text>
          {publication &&
            publication.shortLink && (
              <Config>
                {config => (
                  <PreviewButton
                    text="Preview latest edition"
                    link={`${config.data[0].pdfsShortLinkUrl}${publication.shortLink}`}
                  />
                )}
              </Config>
            )}
          {text && text.text}
        </Text>
        {extraInfo && <Prime title={label}>{extraInfo}</Prime>}
      </ItemContainer>
    );
  }
}

const Section = ({ data, config }) => {
  const { items, heading, subHeading } = data;
  return (
    <section>
      <Head ariaRole="heading">{heading}</Head>
      <Sub>{subHeading}</Sub>
      <Outer>
        <PrintLayout items={items.length}>
          <Trail
            native
            config={config.slow}
            from={{ opacity: 0, transform: 'translateY(15px)' }}
            to={{ opacity: 1, transform: 'translateY(0px)' }}
            items={items}
            keys={d => d.label}
          >
            {item => styles => (
              <PrintItem
                graphqlEndpoint={config.data[0].graphqlEndpoint}
                key={item.label}
                item={item}
                style={styles}
              />
            )}
          </Trail>
        </PrintLayout>
      </Outer>
    </section>
  );
};

const Print = ({ data }) => {
  const { contentfulPage: page, contentfulTable: json } = data;
  const config = JSON.parse(json.childContentfulTableTableJsonNode.internal.content);

  return (
    <AppContainer pageTitle="Print">
      <PrintContainer>
        {page.sections &&
          page.sections.map(section => <Section key={section.id} data={section} config={config} />)}
      </PrintContainer>
    </AppContainer>
  );
};

export const printPageQuery = graphql`
  query PrintPageQuery($id: String!) {
    contentfulTable(title: { eq: "config" }) {
      childContentfulTableTableJsonNode {
        internal {
          content
        }
      }
    }

    contentfulPage(id: { eq: $id }) {
      title
      sections {
        id
        heading
        subHeading
        items {
          label
          text {
            text
          }
          extraInfo
          component
          image {
            fixed(width: 275) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`;

export default Print;
