import React from 'react';
import Back from '../../components/Back';
import PrintMenu from './PrintMenu';
import { Panel } from '../../elements';
function PrintContainer({children}) {
  return (
    <Panel id="content">
      <Back />
      <PrintMenu />
      {children}
    </Panel>
  )
}

export default PrintContainer
