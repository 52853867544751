import React from 'react';
import styled from 'styled-components';
import { Label, Icon } from '../../elements';
const PreviewLabel =styled(Label)`
  margin-bottom: 1rem;
  display: block;
  text-align: center;
`;
const PreviewButton = ({text, link}) => {
  return (
    <PreviewLabel >
      <a rel="noopener noreferrer" target="_blank" href={link}>{text} <Icon>open_in_new</Icon></a>
    </PreviewLabel>
  )
}

export default PreviewButton;
